import React from "react";

import "./Home.scss";

import HeadBanner from "../../components/HeadBanner/HeadBanner";

const Home = () => {
  return (
    <div>
      <HeadBanner />
    </div>
  );
};

export default Home;
